import React from "react";
import {
  Box,
  Center,
  Container,
  Image,
  Heading,
  SimpleGrid,
  Stack,
  Text,
  useBreakpointValue,
  Flex,
  Button
} from "@chakra-ui/react";

import { Size } from "../src/constants";
import { Headline } from "../src/components/text/headline";
import { IndexLayout } from "../src/layouts/indexLayout";
import { getPaginatedDataFPOnly } from "../src/utils/http";
import { ApiLibraryContentService } from "../src/services/http";
import Link from "next/link";
import { ILibraryContent } from "../src/models/content";
import { GetServerSideProps } from "next";
import { ContentList } from "../src/components/content/components/list/contentList";

const incentives = [
  {
    name: "Teil der Initiative",
    imageSrc: "/img/logo-kompetenzzentrum-handel.png"
  },
  {
    name: "Mitglied im",
    imageSrc: "/img/logo-didacta.jpg"
  },
  {
    name: "Teil des Netzwerks",
    imageSrc: "/img/logo-zdi.png"
  },
  {
    name: "Best Practice Beispiel des",
    imageSrc: "/img/logo-bmwe.png"
  }
];

interface ISSRProps {
  content: Array<ILibraryContent>;
}
/**
 * SSR should prevent users to see a placeholder e-mail.
 * Also checks, if the invite code is valid or not.
 */
export const getServerSideProps: GetServerSideProps = async context => {
  const content = await getPaginatedDataFPOnly<ILibraryContent>(ApiLibraryContentService.fetchAllContent);

  return { props: { content: content.slice(0, 12) } };
};

export default function Index({ content }: ISSRProps) {
  return (
    <IndexLayout>
      <Box
        as={"section"}
        mt={Size.NAVBAR_HEIGHT}
        bg={"bg-surface"}
        backgroundImage={"/img/h-3.jpg"}
        backgroundPosition={"center"}
        backgroundSize={"cover"}
        h={{ base: "260px", md: "360px" }}
      >
        <Flex
          width={"full"}
          flexDir="column"
          justifyContent={"center"}
          height={"full"}
          bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
          backdropFilter={"auto"}
          backdropBlur={"6px"}
        >
          <Stack spacing={{ base: "8", md: "10" }}>
            <Stack spacing={{ base: "4", md: "5" }} align={"center"}>
              <Heading
                alignItems={"center"}
                fontFamily="Bitter"
                display={"flex"}
                size={useBreakpointValue({ base: "sm", md: "md" })}
                color={"white"}
                fontSize={"32px"}
              >
                <Box
                  w={20}
                  h={16}
                  backgroundImage={"/img/VIL_Logo_negativ_Bildmarke.svg"}
                  backgroundPosition={"center"}
                  backgroundRepeat="no-repeat"
                  backgroundSize={"contain"}
                ></Box>
                Virtuelles Interaktives Lernen
              </Heading>
              <Text color={"white"} maxW="xl" fontFamily="Bitter" textAlign="center" fontSize="3xl">
                Bildung mit neuer Perspektive
              </Text>
            </Stack>
          </Stack>
        </Flex>
      </Box>
      {/* Featured content packages */}
      <Flex flexDirection={"column"} justifyContent={"center"} alignItems={"center"} bg={"white"} py={8}>
        <Headline
          title={"Entdecken Sie unsere Inhalte"}
          description={`Entdecken Sie die vielfältigen Lerninhalte unserer Mediathek. Mit 360° Videos
          und Anwendungen zu verschiedenen Themen und Unterrichtsfächern wird
          das Lernen mit VR zum Erlebnis.`}
        />
        <ContentList content={content} renderFilter={false} />
        <Link href={"/library"}>
          <Button colorScheme={"cta"} borderRadius="3xl">
            Mehr Inhalte
          </Button>
        </Link>
      </Flex>

      {/* Incentives */}
      <Box backgroundColor={"gray.50"}>
        <Container py={{ base: "12", md: "16" }} maxWidth={"full"}>
          <Stack spacing="8">
            <Headline title={"Unsere Partnerschaften"} as={"h2"} />
            <SimpleGrid gap={{ base: "4", md: "6" }} columns={{ base: 1, md: 2 }} p={{ base: 6, md: 16 }}>
              {incentives.map(({ name, imageSrc }) => (
                <Center
                  minH={"160px"}
                  key={name}
                  bg={"bg-surface"}
                  borderRadius="3xl"
                  py={{ base: "4", md: "8" }}
                  boxShadow={"sm"}
                  backgroundColor={"white"}
                  shadow={"xl"}
                >
                  <Image src={imageSrc} alt={name} h={"auto"} maxW={"180px"} fill={"emphasized"} />
                </Center>
              ))}
            </SimpleGrid>
          </Stack>
        </Container>
      </Box>
    </IndexLayout>
  );
}
