import React from "react";
import { Box, Flex } from "@chakra-ui/react";

import { Menu } from "./shared/menu";
import Footer from "./shared/footer";

export const IndexLayout: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <Flex w={"full"} flexDirection={"column"}>
      <Menu />
      <Box w={"full"} flexGrow={1}>
        {children}
      </Box>
      <Footer isMarketplace={false} />
    </Flex>
  );
};
