import React from "react";
import { Container, Center, VStack, Divider, Text, As, Button } from "@chakra-ui/react";
import { useRouter } from "next/router";
import { ArrowLeftIcon } from "@heroicons/react/solid";

type PageHeadlineProps = {
  title: string;
  description?: string;
  as?: As;
  isMarketplace?: boolean;
  href?: string;
};

export const Headline: React.FC<PageHeadlineProps> = ({ title, description, as, href, isMarketplace }) => {
  const router = useRouter();

  const handleGoBack = () => {
    if (href) {
      router.push(href);
    } else {
      router.back();
    }
  };
  return (
    <>
      {href && (
        <Button
          colorScheme={isMarketplace ? "lightBlue" : "darkBlue"}
          leftIcon={<ArrowLeftIcon className="w-4 h-4" />}
          borderRadius={"3xl"}
          float={"left"}
          m={4}
          cursor="pointer"
          as={"a"}
          onClick={handleGoBack}
        >
          Zurück
        </Button>
      )}
      <Container maxWidth={"container.md"} pb={4} pt={10}>
        <Center>
          <VStack w={"full"}>
            <Text
              as={as ? as : "h1"}
              color={isMarketplace ? "white" : "darkBlue.500"}
              fontSize={"32px"}
              fontFamily="Bitter"
              fontWeight={600}
            >
              {title}
            </Text>
            <Text color={"gray.500"} textAlign={"center"}>
              {description}
            </Text>
            {description && <Divider width={"full"} />}
          </VStack>
        </Center>
      </Container>
    </>
  );
};
